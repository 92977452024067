.footerHeaderText {
    font-family: 'Poppins-Bold';
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;

    color: #BBBDBF;
}

.footerContentText {
    font-family: 'Poppins-Regular';
    font-weight: 400;
    font-size: 16px;

    color: #BBBDBF;
}

.bg-black {
    background-color: black;
}

.footerCopyrightText {
    font-family: 'Poppins-Regular';
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;

    color: black;

    @media screen and (max-width: 992px) {
        font-size: 16px;
    }
    @media screen and (max-width: 677px) {
        font-size: 14px;
    }
}

.footerBorder1 {
    border: 1px solid white;
    border-width: 0 1px 0 1px;

    @media screen and (max-width: 992px) {
        border-width: 1px 0 1px 0;
    }
}

.footerBorder2 {
    border: 1px solid white;
    border-width: 0 1px 0 0px;

    @media screen and (max-width: 992px) {
        border-width: 0px 0 1px 0;
    }
}