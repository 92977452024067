.headerBg {
    // background-image: url(../img/home/header_bg.png);
    // background-size: 100% 100%;
    // background-position: center;
    // @media screen and (max-width: 992px) {
    //     background-size:cover;
    //     background-position: 70%;
    // }
    background-color: #000000;
}

.header1Bg {
    background-image: url(../img/home/header1_bg.png);
    background-size: 100% 100%;
    background-position: center;
    padding-bottom: 15vh;

}

.logoSubText {
    font-family: "D-Din Condensed";
    font-weight: 500;
    font-size: 16px;
}

.headerDesc {
    font-family: "Poppins-Light";
    font-weight: 400;
    font-size: 18px;

    @media screen and (max-width: 767px) {
        font-size: 15px;
    }
}


.home-header-container {
    padding-top: 15vh;
}

.headerButtonBox {
    border: 1px solid white;
    padding: 15px;
    width: 150px;
    background: #1C1C1C;
}

.homeHeader-buttonText {
    font-family: "Poppins-Light";
    font-weight: 400;
    font-size: 20px;
    color: #A6A8AB;

    @media screen and (max-width: 767px) {
        font-size: 16px;
    }
}

.visionBg2 {
    background-image: url(../img/home/vision2_bg2.png);
    background-size: 65% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-position: right;
}

.visionText {
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 25px;
    color: black;

    @media screen and (max-width: 767px) {
        font-size: 25px;
    }

    @media screen and (max-width: 300px) {
        font-size: 18px;
    }
}

.visionDescText {
    font-family: "Poppins-Bold";
    font-weight: 400;
    font-size: 35px;
    color: black;

    @media screen and (max-width: 767px) {
        font-size: 25px;
    }

    @media screen and (max-width: 300px) {
        font-size: 18px;
    }
}

.visionDescText2 {
    font-family: "Poppins-Bold";
    font-weight: 400;
    font-size: 35px;
    color: rgba(146, 148, 151, 1);

    @media screen and (max-width: 767px) {
        font-size: 25px;
    }

    @media screen and (max-width: 300px) {
        font-size: 18px;
    }
}

.visionIcon1 {
    top: 10%;
    left: 5%;
}

.visionIcon2 {
    top: 10%;
    right: 5%;
}

.visionIcon3 {
    bottom: 10%;
    left: 5%;
}

.visionIcon4 {
    bottom: 10%;
    right: 5%;
}

.omoBg {
    background-image: url(../img/home/omo_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 992px) {
        background-size: cover;
        background-position: center;
    }
}

.omoSubTitle {
    font-family: "Poppins-Bold";
    font-weight: 700;
    font-size: 20px;
    color: white;
}

.teamMemberName {
    font-family: 'Poppins-Medium';
    font-weight: 500;
    font-size: 23px;
    line-height: 42px;
    color: #000000;
}

.teamMemberPosition {
    font-family: "Poppins-Regular";
    font-weight: 400;
    font-size: 18px;
    color: black;
}

.affiliationBg {
    background-image: url(../img/home/affiliation_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.affTitle {
    color: #A6A8AB;
}

.headerArrow {
    width: 50px;
}

.omo-op1 {
    top: 0;
    left: 30%;
    width: 18%;
    height: 7%;

    @media screen and (max-width: 1200px) {
        top: 0;
        left: 26%;
        width: 20%;
        height: 7%;
    }

    @media screen and (max-width: 992px) {
        top: 0;
        left: 23%;
        width: 23%;
        height: 7%;
    }

    @media screen and (max-width: 768px) {
        top: 0;
        left: 16%;
        width: 30%;
        height: 7%;
    }
}

.omo-op2 {
    top: 0;
    left: 53%;
    width: 18%;
    height: 7%;

    @media screen and (max-width: 1200px) {
        top: 0;
        left: 54%;
        width: 21%;
        height: 7%;
    }

    @media screen and (max-width: 992px) {
        top: 0;
        left: 55%;
        width: 23%;
        height: 7%;
    }

    @media screen and (max-width: 768px) {
        top: 0;
        left: 57%;
        width: 28%;
        height: 7%;
    }
}

.omo-op3 {
    top: 84%;
    left: 20%;
    width: 16%;
    height: 12%;

    @media screen and (max-width: 1200px) {
        top: 84%;
        left: 14%;
        width: 18%;
        height: 12%;
    }

    @media screen and (max-width: 992px) {
        top: 84%;
        left: 9%;
        width: 20%;
        height: 12%;
    }

    @media screen and (max-width: 768px) {
        top: 84%;
        left: 0%;
        width: 24%;
        height: 12%;
    }
}

.omo-op4 {
    top: 89%;
    left: 42%;
    width: 14%;
    height: 12%;

    @media screen and (max-width: 1200px) {
        top: 89%;
        left: 40%;
        width: 18%;
        height: 12%;
    }

    @media screen and (max-width: 992px) {
        top: 89%;
        left: 40%;
        width: 18%;
        height: 12%;
    }

    @media screen and (max-width: 768px) {
        top: 89%;
        left: 37%;
        width: 22%;
        height: 12%;
    }
}

.omo-op5 {
    top: 84%;
    left: 62%;
    width: 18%;
    height: 12%;

    @media screen and (max-width: 1200px) {
        top: 84%;
        left: 64%;
        width: 23%;
        height: 12%;
    }

    @media screen and (max-width: 992px) {
        top: 84%;
        left: 66%;
        width: 25%;
        height: 12%;
    }

    @media screen and (max-width: 768px) {
        top: 84%;
        left: 70%;
        width: 33%;
        height: 12%;
    }
}