.inputTitle {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 41px;
    text-align: justify;
    text-transform: uppercase;

    color: #000000;

    @media screen and (max-width: 677px) {
        font-size: 15px;
    }
}

.contactUs-textBox {
    border: 1.5px solid #000000;
    border-radius: 0 0 30px 30px;
    height: 50px;
    font-size: 18px;
}

.contactUs-textArea {
    border: 1.5px solid #000000 !important;
    border-radius: 0 0 30px 30px !important;
    min-height: 150px !important;
    font-size: 18px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
}

.contactDetailsTitle {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 41px;
    text-align: justify;
    text-transform: uppercase;

    color: #000000;
}

.contactDetailsContent {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    /* or 111% */


    color: #000000;
}

.bgBlack {
    background-color: black;

}

.sendBtn {
    margin-left: auto;
    margin-right: 0;
    padding: 5px 25px 5px 25px;
    border-radius: 15px;
    cursor: pointer;
}

.error {
    color: red;
}