.nftHeaderBg {
    background-image: url(../img/nft/foxcat_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 677px) {
        background-size: cover;
        background-position: center;
    }
}

.outer-nftContainer {
    background-color: #282828;
}

.nftDesc {
    font-family: 'Poppins-Light';
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    color: #FFFFFF;

    @media screen and (max-width: 677px) {
        font-size: 18px;
        line-height: 30px;
    }

    @media screen and (max-width: 300px) {
        font-size: 15px;
        line-height: 30px;
    }
}

.nftBg {
    background-image: url(../img/nft/nft_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 110vh;

    @media screen and (max-width: 992px) {
        background-size: cover;
        background-position: center;
    }
}

.button-img1 {
    width: 200px;
    position: absolute;
    top: 20%;
    left: 16%;

    @media screen and (max-width: 992px) {
        display: none;
    }
}

.button-img2 {
    width: 200px;
    position: absolute;
    bottom: 18%;
    right: 14%;

    @media screen and (max-width: 992px) {
        display: none;
    }
}

.mobileNFT-btn {
    width: 90%;
    bottom: 5%;
}

.button-img1-sm {
    width: 80%;
}

.button-img2-sm {
    width: 80%;
}

.genesisBg {
    background-image: url(../img/nft/genesisBg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 60vh;

    @media screen and (max-width: 992px) {
        background-size: cover;
        background-position: 60%;
    }

    @media screen and (max-width: 677px) {
        background-size: cover;
        background-position: 60%;
    }
}

.utilityBg {
    background-image: url(../img/nft/utilityBg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 60vh;

    @media screen and (max-width: 992px) {
        background-size: cover;
        background-position: 60%;
    }

    @media screen and (max-width: 677px) {
        background-size: cover;
        background-position: 78%;
    }
}

.NFT-title {
    font-family: 'Poppins-SemiBold';
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 82px;
    text-align: center;

    color: #FFFFFF;


    @media screen and (max-width: 677px) {
        font-size: 60px;
        line-height: 80px;
    }

    @media screen and (max-width: 300px) {
        font-size: 50px;
        line-height: 80px;
    }
}

.NFTDesc {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 33px;
    text-align: center;

    color: #FFFFFF;
}

.greyBg {
    background-color: #1C1C1C;
}

.socialMediaText {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    
    color: #FFFFFF;

    @media screen and (max-width: 677px) {
        font-size: 15px;
    }

    @media screen and (max-width: 300px) {
        font-size: 11px;
    }
}

.button-img3 {
    width: 13%;
    position: absolute;
    top: 20%;
    left: 18%;
}

.button-img4 {
    width: 13%;
    position: absolute;
    bottom: 18%;
    right: 18%;
}

.socialMediaBox {
    padding: 30px 20px 30px 20px;
}

.socialMediaTitle {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    /* identical to box height */

    text-align: center;

    color: #A6A8AB;
}