.aboutHeaderBg {
    background-image: url(../img/about/about_headerBg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.outer-aboutContainer {
    background-color: #515151;
}

.about-titleText {
    font-family: "Poppins-Light";
    font-weight: 400;
    font-size: 20px;
    color: white;

    @media screen and (max-width: 767px) {
        font-size: 16px;
    }
}

.about-header-container {
    height: 60vh;
}

.about_logoSubText {
    font-family: "D-Din Condensed";
    font-weight: 500;
    font-size: 50px;

    @media screen and (max-width: 767px) {
        font-size: 30px;
    }

    @media screen and (max-width: 300px) {
        font-size: 23px;
    }
}

.grayBg {
    background: #1C1C1C;
}

.aboutVisionBg {
    background-image: url(../img/about/vision_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.blackBg {
    background: black;
}

.visionHeader {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 38px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: black;
}

.visionContents {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 30px;
    text-align: center;

    color: black;
}

.missionBorderBox {
    border: 1px solid white;
    border-width: 0 1px 0 1px;

    @media screen and (max-width: 992px) {
        border-width: 1px 0 1px 0;
    }
}

.rulesHeaderText {
    font-family: 'Poppins-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 82px;

    color: #FFFFFF;

    @media screen and (max-width: 992px) {
        font-size: 50px;
    }

    @media screen and (max-width: 677px) {
        font-size: 30px;
    }
}

.rulesHeaderDesc {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    // text-align: justify;

    color: #FFFFFF;

    @media screen and (max-width: 992px) {
        font-size: 18px;
    }

    @media screen and (max-width: 677px) {
        font-size: 15px;
    }
}

.cultureBg {
    background-image: url(../img/about/culture_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 677px) {
        background-size: cover;
        background-position: 80%;
    }
}

.principleBg {
    background-image: url(../img/about/principle_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 677px) {
        background-size: cover;
        background-position: 20%;
    }
}

.aboutBottomBg {
    background-image: url(../img/about/title_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 677px) {
        background-size: cover;
    }
}

.greyBg2 {
    background-color: #282828
}