.academyDesc {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    // text-align: justify;
    color: black;

    @media screen and (max-width: 767px) {
        font-size: 15px;
    }
    @media screen and (max-width: 300px) {
        font-size: 15px;
    }
}
