#users{
    background-image: url('../img/users/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .main-title{
        font-weight: 700;
        font-size: 60px;
        font-family: "Poppins-Bold";
    }

    .main-description{
        font-family: 'Poppins-Regular';
        font-weight: 400;
        font-size: 18px;
        text-align: center;
    }
    .floatObj1{
        position: absolute;
        bottom: -50px;
        right: 0;
        z-index: 0;
    }
    .floatObj2{
        position: absolute; 
        left: 20rem;
        z-index: 0;
        top: -50px;
    }
    .floatObj3{
        position: absolute; 
        right: 40%;
        z-index: 0;
        top: -100px;
    }

    #socialfi{
        .title{
            font-family: 'Poppins-Bold';
            font-weight: 700;
            font-size: 40px;
        }
        .desc{
            font-family: 'Poppins-Regular';
            font-weight: 400;
            font-size: 20px;
        }
        .content-left-container, .content-right-container{
            position: relative;
        }
        .content-left{
            position: absolute; 
            top: 30%;
        }
        .content-right{
            position: absolute; 
            bottom: 0;
        }
    }
    #tokenomic{
        .title{
            font-family: 'Poppins-Bold';
            font-weight: 700;
            font-size: 40px;
        }
        .coinName{
            font-family: 'Poppins-SemiBold';
            font-weight: 600;
            font-size: 30px;
        }
        ul{
            padding-left: 1rem;
            margin-bottom: 0.5rem;
        }
        li{
            font-family: 'Poppins-Regular';
            font-weight: 600;
            font-size: 16px;
        }
        .token-desc{
            font-family: 'Poppins-Regular';
            font-weight: 400;
            font-size: 14px;
        }
        .flow-border{
            border: 1px solid #000000;
            filter: drop-shadow(0px 17px 29px rgba(0, 0, 0, 0.32));
            backdrop-filter: blur(40px);
        }
        .flow-desc{
            font-family: 'Poppins-Regular';
            font-weight: 600;
            font-size: 16px;
            display: block;
            text-align: center;
        }
        .token-img{
            height: 300px;
        }
        .tokonomic-details{
            display: none;
        }
        .tokonomic-details.active {
            display: inline-block;
            animation: fadeIn 3s;
        }   
        .row .token-column{
            transition: 500ms;
        }
        .token-column-inner{
            // border: 2px solid black;
            display: flex;
            background-image: url(../img/users/coin_border.png);
            background-size: 100% 110%;
            
            flex-direction: column;
            @media (min-width: 1025px){
                justify-content: center;
            }
            .bottom-text{
                width: 100%;
            }
        }
        
        .coin-bg{
            background-image: url('../img/users/coin_bg.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            display: inline-block;
            vertical-align: middle;
        }
    }

    #create2earn{
        background-image: url('../img/users/method1_bg.png'); 
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
        position: relative;
        @media screen and (max-width: 667px) {
            background-position: 40%;

        }

        .title{
            font-family: 'Poppins-Bold';
            font-weight: 700;
            font-size: 50px;
        }
        .desc{
            font-family: 'Poppins-Regular';
            font-weight: 400;
            font-size: 18px;
            display: block;
        }
    }

    #watch2earn{
        background-image: url('../img/users/method2_bg.png'); 
        background-position: center;
        background-repeat: no-repeat; 
        background-size: cover;
        position: relative;
        .title{
            font-family: 'Poppins-Bold';
            font-weight: 700;
            font-size: 50px;
        }
        .desc{
            font-family: 'Poppins-Regular';
            font-weight: 400;
            font-size: 18px;
            display: block;
        }
    }

    @media (max-width: 767px) {
        .main-title{
            font-size: 30px !important;
        }
        .main-description{
            font-size: 14px;
            text-align: center;
        }
        .floatObj1{
            bottom: 10px;
            .floating2{
                width: 100px;
            }
        }
        .floatObj2{
            top: -7%;
            left: 10%;
            .floating2{
                width: 50%;
            }
        }
        .floatObj3{
            top: -10%;
            .floating2{
                width: 100px;
            }
        }
        #socialfi{
            .title{
                font-size: 20px;
            }
            .content-left{
                position: relative; 
                top: 0%;
            }
            .content-right{
                position: relative; 
                top: 0;
            }
            .desc{
                font-size: 14px !important;
            }
        }
        #tokenomic{
            .title{
                font-size: 20px;
            }
            .coinName{
                font-size: 15px;
            }
            li{
                font-size: 12px;
            }
            .token-desc{
                font-size: 12px;
            }
            .tokonomic-details{
                margin-top: 1rem;
            }
        }
        #create2earn, #watch2earn{
            .title{
                font-size: 20px;
            }
            .desc{
                font-size: 14px;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 1024px){
        .main-title{
            font-size: 30px;
        }
        .main-description{
            font-size: 14px;
            text-align: center;
        }
        .floatObj1{
            bottom: 20%;
            .floating2{
                width: 100px;
            }
        }
        .floatObj2{
            top: -7%;
            left: 10%;
            .floating2{
                width: 50%;
            }
        }
        .floatObj3{
            top: -10%;
            .floating2{
                width: 100px;
            }
        }
        #tokenomic{
            .tokonomic-details{
                margin-top: 1rem;
            }
        }
        #socialfi{
            .title{
                font-size: 25px;
            }
            .content-left{
                position: relative; 
                top: 15%;
            }
            .content-right{
                position: absolute; 
                bottom: 0px;
            }
            .desc{
                font-size: 14px !important;
            }
        }
        #create2earn, #watch2earn{
            .title{
                font-size: 25px !important;
            }
            .desc{
                font-size: 14px;
            }
        }
    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

#creators{
    .header-section{
        background: #000000;
    }
    .floatObj1{
        img{
            position: absolute; 
            top: -3%; 
            left: -5%; 
            z-index: 1;
        }
    }
    .floatObj2{
        img{
            position: absolute; 
            top: -10%; 
            right: 0; 
            z-index: 1;
        }
    }
    .floatObj3{
        img{
            position: absolute; 
            top: 5%; 
            right: 20%; 
            z-index: 1;
        }
    }
    .floatObj4{
        img{
            position: absolute;
            top: 0; 
            left: 5%; 
            z-index: 1;
            width: 10%;
        }
    }
    .floatObj5{
        img{
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .floatObj6{
        img{
            position: absolute;
            bottom: -25%;
            right: -20%;
        }
    }
    .content-container{
        background-image: url('../img/users/bg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
    }
    .title{
        font-weight: 700;
        font-size: 60px;
        font-family: "Poppins-Bold";
    }
    .main-description{
        font-family: 'Poppins-Regular';
        font-weight: 400;
        font-size: 18px;
        text-align: center;
        display: block;
    }
    .community{
        padding-top: 10rem;
        padding-bottom: 10rem;
        position: relative;
    }
    .community-header{
        font-family: 'Poppins-Bold';
        font-weight: 700;
        font-size: 60px;
        z-index: 999;
    }
    .community-desc{
        font-family: 'Poppins-Regular';
        font-weight: 400;
        font-size: 18px;
    }

    @media (max-width: 767px) {
        .title{
            font-size: 30px;
        }
        .main-description, .community-desc{
            font-size: 16px;
        }
        .community-header{
            font-size: 30px;
        }
        .floatObj1{
            img{
                top: 0%; 
                left: 0; 
                width: 20%;
            }
        }
        .floatObj2{
            img{
                top: -5%; 
                right: 0; 
                width: 30%;
            }
        }
        .floatObj3{
            img{
                top: 40%; 
                right: 20%; 
                width: 5%;
            }
        }
        .floatObj4{
            img{
                top: 10%; 
                left: 5%; 
                width: 10%;
            }
        }
        .floatObj5{
            img{
                top: -10%;
                left: 0;
                width: 40%;
            }
        }
        .floatObj6{
            img{
                position: absolute;
                bottom: -10%;
                right: 0;
                width: 30%;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 1024px){
        .title, .community-header{
            font-size: 30px;
        }
        .main-description, .community-desc{
            font-size: 16px;
        }
        .floatObj1{
            img{
                top: 30%; 
                left: 0; 
                width: 20%;
            }
        }
        .floatObj2{
            img{
                top: 20%; 
                right: 0; 
                width: 30%;
            }
        }
        .floatObj3{
            img{
                top: 40%; 
                right: 20%; 
                width: 5%;
            }
        }
        .floatObj4{
            img{
                top: 10%; 
                left: 5%; 
                width: 10%;
            }
        }
        .floatObj5{
            img{
                top: -10%;
                left: 0;
                width: 40%;
            }
        }
        .floatObj6{
            img{
                position: absolute;
                bottom: -10%;
                right: 0;
                width: 30%;
            }
        }
    }
}

.floating {
    animation-name: floating;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}

@keyframes floating {
    0% {
        transform: translate(0px, 0px);
    }

    30% {
        transform: translate(-50px, 20px);
    }

    70% {
        transform: translate(50px, 40px);
    }

    100% {
        transform: translate(0px, -0px);
    }
}


.floating2 {
    animation-name: floating2;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-top: 5px;
}

@keyframes floating2 {
    0% {
        transform: translate(0px, 20px);
    }

    50% {
        transform: translate(0px, -20px);
    }

    100% {
        transform: translate(0px, 20px);
    }
}
