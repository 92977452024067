.navbarText {
    font-family: 'Poppins-Regular';
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;

    color: white;
}

.navbarsubText {
    font-family: 'Poppins-Regular';
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;

    color: white;
}

.dropdownIcon {
    margin-top: -5px;
    font-size: 15px;
}

.dropdown-menu:before {
    
}

.sidebar-collapse .navbar-collapse:before {
    @media screen and (max-width: 991px) {
        background: black !important;
    }
}

.sidebar-collapse .navbar .dropdown.show .dropdown-menu {
    @media screen and (max-width: 991px) {
        height: 100%;
    }
}

.navLoginBtn {
    float: right;
    position: absolute;
    top: 35%;
    right: 2%;
}

.navLoginBtn2 {
    // float: right;
    // position: absolute;
    // top: 70%;
    // right: 2%;
    padding-left: 8%;
    padding-top: 5%;
}

.hoverEffect-dropdown:hover {
    text-decoration: none;
}

.sidebar-collapse .navbar-collapse {
    @media screen and (max-width: 300px) {
        width: 200px !important;
    }
}

.nav-open .sidebar-collapse .navbar-translate {
    @media screen and (max-width: 300px) {
        transform: translate3d(-200px, 0, 0);
    }
}

.navbarLogo {
    width: 120px;
}

.loginLogo {
    width: 40px;
}

.loginText {
    display: none;
}

.navLoginBtn:hover {
    .loginLogo2 {
        display: none;
        transition: 100ms;
    }
    .loginText {
        display: block;
        transition: 100ms;
    }
}