.tokenomicsBg {
    background-image: url(../img/tokenomics/header_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 677px) {
        background-size: cover;
        background-position: center;
    }
}

.tokeTitle {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 90px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;

    @media screen and (max-width: 677px) {
        font-size: 50px;
    }
    @media screen and (max-width: 300px) {
        font-size: 35px;
    }
}

.tokeDetails {
    font-family: 'Poppins-Regular';
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;
    text-align: center;

    color: #FFFFFF;

    @media screen and (max-width: 677px) {
        font-size: 18px;
        line-height: 30px;
    }
    @media screen and (max-width: 300px) {
        font-size: 15px;
        line-height: 30px;
    }
}

.coinBg {
    background-image: url(../img/tokenomics/coin_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.coin-title {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 42px;    
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;

    @media screen and (max-width: 677px) {
        font-size: 20px;
    }
}

.coin-line {
    border: 1px solid #FFFFFF;
    max-width: 80%;

    @media screen and (min-width: 992px) {
        margin-left: 0;
    }
}

.coin-subtitle {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 82px;
    text-align: center;
    color: #FFFFFF;

    @media screen and (max-width: 677px) {
        font-size: 30px;
    }
}

.coin-supply {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    
    color: #939598;
    
    @media screen and (max-width: 677px) {
        font-size: 22px;
    }
    @media screen and (max-width: 300px) {
        font-size: 13px;
    }
}

.supplyBox {
    border: 1px solid white;
    border-radius: 30px;
}

.coin-functions {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;

    color: #FFFFFF;
}

.coin-functions-details {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;

    color: #FFFFFF;
}

.functionDiv {
    @media screen and (max-width: 992px) {
        justify-content: center;
    }
}

.functionContainer {
    padding: 5px 30px 5px 30px;
    border: 0.59px solid #FFFFFF;
    border-radius: 30px;
}

.coin-details {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;

    color: #FFFFFF;
}

.coin1Bg {
    background-image: url(../img/tokenomics/coin_bg1.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.coin2Bg {
    background-image: url(../img/tokenomics/coin_bg2.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}