#productbrand {
    #carousel1 {
        .carousel-status {
            display: none;
        }

        .carousel .control-next.control-arrow:before {
            display: none;
        }

        .carousel .control-prev.control-arrow:before {
            display: none;
        }

        .carousel .control-dots .dot.selected,
        .carousel .control-dots .dot:hover {
            background-color: black;
        }

        .carousel .control-dots .dot {
            border: 1px solid black;
        }
    }

    .headerDetails {
        font-family: 'Poppins-Light';
        font-weight: 300;
        font-size: 23px;
        line-height: 38px;
        text-align: center;

        color: #131313;

        @media screen and (max-width: 677px) {
            font-size: 18px;
            line-height: 25px;
        }
    }


    .headerDetailsBg {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
    }

    #carousel2 {
        .carousel-status {
            display: none;
        }

        .carousel .control-arrow,
        .carousel.carousel-slider .control-arrow {
            opacity: 1;
            background: none;
            font-size: 40px;
        }

        .carousel .control-dots {
            display: none;
        }
    }

    .bordoxLogo_text {
        font-family: 'Poppins-SemiBold';
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #000000;

        @media screen and (max-width: 677px) {
            font-size: 13px;
        }
    }

    .bordox_introText {
        font-family: 'Poppins-Regular';
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        /* or 138% */

        text-align: justify;

        color: #131313;
    }

    .instaIcon {
        font-size: 30px;
        color: rgba(255, 204, 0, 1);
    }

    .instaText {
        font-family: 'Poppins-SemiBold';
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        text-align: justify;

        color: #58595B;
    }

    .bordox_introBg {
        background-image: url(../img/productbrand/bordox_bg1.png);
        background-size: 100% 80%;
        background-position: top;
        background-repeat: no-repeat;

        @media screen and (max-width: 677px) {
            background-size: cover;
            background-position: center;
        }
    }

    .bordox_introBg2 {
        background-image: url(../img/productbrand/bordox_bg2.png);
        background-size: 100% 100%;
        background-position: top;
        background-repeat: no-repeat;

        @media screen and (max-width: 677px) {
            background-size: cover;
            background-position: center;
        }
    }

    .bordox_introBg3 {
        background-image: url(../img/productbrand/bordox_bg3.png);
        background-size: 100% 100%;
        background-position: top;
        background-repeat: no-repeat;

        @media screen and (max-width: 677px) {
            background-size: cover;
            background-position: center;
        }
    }

    .eventDateText {
        font-family: 'Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 33px;
        text-align: center;
        text-transform: uppercase;

        color: #414042;

        @media screen and (max-width: 677px) {
            font-size: 14px;
        }
    }

    .eventDescText {
        font-family: 'Poppins-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height */

        text-align: center;

        color: #BCBEC0;

        @media screen and (max-width: 677px) {
            font-size: 15px;
            line-height: 20px;
        }
    }

}

.MuiSlider-markLabel {
    display: none !important;
}

.MuiSlider-markLabel {
    display: none !important;
}

.MuiSlider-valueLabel {
    display: none !important;
}

.MuiSlider-root {
    color: white !important;
}

.MuiSlider-thumb .Mui-active {
    box-shadow: rgba(255, 204, 0, 1) !important;
}

.MuiSlider-thumb:before {
    background-color: rgba(255, 204, 0, 1) !important;
}

.slide2 {
    min-height: 350px;
}

.timelineYearText {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 33px;
    text-align: center;

    color: #FFFFFF;
}

.sliderBlockDiv {
    top: 0;
}

.timelineNext {
    position: absolute;
    right: -5%;
    top: 40%;

    @media screen and (max-width: 960px) {
        right: 0%;
        top: 40%;
    }
}

.timelinePrev {
    position: absolute;
    left: -5%;
    top: 40%;
    z-index: 1000;
    @media screen and (max-width: 960px) {
        left: 0%;
        top: 40%;
    }
}

.nextImg {
    cursor: pointer;
}

.prevImg {
    cursor: pointer;
}

$finalHeight: 100vw / 2.5;
$finalWidth: 2.5 * $finalHeight;
$scrollBarHeight: 1px;
$finalWidth-sm: 90vw;



::-webkit-scrollbar {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}

::-webkit-scrollbar-button {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}


.horizontal-scroll-wrapper {
    position: absolute;
    display: block;
    top: 5em;
    left: 0;
    width: calc(#{$finalHeight} + #{$scrollBarHeight});
    max-height: $finalWidth;
    margin: 0;
    padding-top: $scrollBarHeight;
  //   background: #abc;
    overflow-y: auto;
    height: 100vw;
    overflow-x: scroll;
    transform: rotate(-90deg) translateY(-$finalHeight );
    transform-origin: top right;
    text-align: center;
    justify-content: center;
    @media screen and (max-width: 960px) {
        top: 2em;
    }

    @media screen and (max-width: 960px) {
        max-height: $finalWidth-sm;
        
    }

    & > .project-slide-container {
      display: block;
      padding: 5px;
      // background: #cab;
      transform: rotate(90deg);
      transform-origin: right top;
      // border: 3px solid #fff;
    }
  }

  .scrollHorizontalContainer {
    height: $finalHeight + 5;
    @media screen and (max-width: 667px) {
        height: $finalHeight + 20;
    }
  }
  
  .squares {
    padding: $finalHeight 0 0 0;
    & > .project-slide-container {
      width: $finalHeight;
      height: $finalHeight;
      // margin: 10px 0;
      
    }
    .project-slide{
      background-size: cover;
      background-position: center;
      height: 100%;
      position: relative;
      filter: grayscale(0);
    }
  
    .project-slide-default{
      background-size: cover;
      background-position: center;
      height: 100%;
      position: relative;
      filter: grayscale(0);
    }
  
    .project-slide:hover{
      filter: grayscale(0);
      transition: 100ms;
    }
  
    .project-img-overlay{
      background: linear-gradient(180deg, rgba(24, 24, 24, 0) 45.62%, #181818 100%);
      background-size: cover;
      height: 100%;
      display: flex;
      align-items: flex-end;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 4rem;
    }
    .project-slide-title{
      font-family: 'FigtreeSemiBold';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #EFEFEF;
    }
  }
  
  .home-project-title{
      font-family: 'Figtree';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
      color: #F6F6F6;
      @media only screen and (max-width: 1024px) {
          font-size: 30px;
          line-height: 38px;
      }
  }
  .project-slide-title-container{
      display: flex; 
      align-items: flex-start; 
      height: 3rem;
  }