.newsEventBg {
    background-image: url(../img/newsandevent/header_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 677px) {
        background-size: cover;
        background-position: center;
    }
}

.eventListBg {
    background-image: url(../img/newsandevent/list_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 677px) {
        background-size: cover;
        background-position: center;
    }
}

.newsEventTitle {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 60px;
    line-height: 90px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;

    @media screen and (max-width: 677px) {
        font-size: 50px;
    }

    @media screen and (max-width: 300px) {
        font-size: 35px;
    }
}

.dateText {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 20px;

    letter-spacing: 0.3em;
    text-transform: uppercase;

    color: #FFCC00;
}

.eventTitle {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFFFFF;

    @media screen and (max-width: 767px) {
        font-size: 20px;
    }

    @media screen and (max-width: 300px) {
        font-size: 18px;
    }
}

.eventVenue {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;

    @media screen and (max-width: 767px) {
        font-size: 16px;
    }

    @media screen and (max-width: 300px) {
        font-size: 16px;
    }
}

.eventDesc {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 275;
    font-size: 15px;
    line-height: 20px;
    color: #FFFFFF;
}

.newsArrow {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 52px;

    text-align: justify;

    color: #FFFFFF;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: white;
}

#newsEvent {
    .carousel-status {
        display: none;
    }

    .carousel .control-next.control-arrow:before {
        display: none;
    }

    .carousel .control-prev.control-arrow:before {
        display: none;
    }
}