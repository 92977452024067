.share2earnBg {
    background-image: url(../img/share2earn/header_bg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;

    @media screen and (max-width: 677px) {
        background-size: cover;
        background-position: center;
    }
}

.share2earnTitle {
    font-family: 'Grotesk Rounded';
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 73px;
    text-align: center;

    color: #FFFFFF;

    @media screen and (max-width: 677px) {
        font-size: 50px;
    }

    @media screen and (max-width: 300px) {
        font-size: 35px;
    }
}

.share2earnSubtitle {
    font-family: 'Poppins-Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #A7A9AC;
}

.share2earnDetails {
    font-family: 'Poppins-Light';
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    
    color: #FFFFFF;

    @media screen and (max-width: 677px) {
        font-size: 18px;
        line-height: 30px;
    }

    @media screen and (max-width: 300px) {
        font-size: 15px;
        line-height: 30px;
    }
}


.missionBg {
    // background-image: url(../img/share2earn/mission_bg.png);
    // background-size: 100% 100%;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-position: right;

    background-color: #1C1C1C;
}

.missionText {
    font-family: 'Poppins-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 41px;
    text-align: center;

    color: #FFFFFF;
}

.missionDescText1 {
    font-family: 'Poppins-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 50px;
    text-align: center;

    color: #E6E7E8;


    @media screen and (max-width: 960px) {
        font-size: 30px;
        line-height: 40px;
    }

    @media screen and (max-width: 667px) {
        font-size: 25px;
        line-height: 40px;
    }
}

.missionDescText2 {
    font-family: 'Poppins-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 50px;
    text-align: center;
    color: #FFF29C;

    @media screen and (max-width: 960px) {
        font-size: 30px;
        line-height: 40px;
    }

    @media screen and (max-width: 667px) {
        font-size: 25px;
        line-height: 40px;
    }
}

.bonusBg {
    background-image: url(../img/share2earn/bonusBg.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-position: right;
}

.bonusTitle {
    font-family: 'Poppins-Bold';
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 38px;
    text-align: center;
    color: #000000;
}

.bonusDetails {
    font-family: 'Poppins-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;

    color: #000000;
}

.borderLeftContainer {
    border: 2px solid black;
    border-width: 0 0 0 2px;

    @media screen and (max-width: 667px) {
        border: none;
    }
}

.infoBtn {
    background-color: #FFFFFF;
    padding: 10px 20px 10px 20px;
    border-radius: 50px;
    border: 1px solid #1C1C1C;
}

.infoBtnText {
    font-family: 'Poppins-SemiBold';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: center;

    color: black;
}

.infoBtnIcon {
    margin-top: -2px;
}

.memberLoginText {
    font-family: 'Poppins-Light';
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    text-decoration: underline;
    color: black;
}